import React, { Component } from 'react';
import Navbar from "react-bootstrap/es/Navbar";
import Nav from "react-bootstrap/es/Nav";
import NavItem from "react-bootstrap/es/NavItem";

export default class Header extends Component {
    render(){
        const {auth: isAuthenticated} = this.props;
        return (
            <div>
                <Navbar inverse collapseOnSelect>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <a href="/">میز</a>
                        </Navbar.Brand>
                        <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav>
                        </Nav>
                        <Nav pullLeft>
                            {/*{global.config.LOCALE === "fa" ?*/}
                                {/*<NavItem eventKey={1} onClick={() => this.props.changeLang("fa")} style={{"backgroundColor": "rgb(75, 124, 202)"}}>FA</NavItem> :*/}
                                {/*<NavItem eventKey={1} onClick={() => this.props.changeLang("fa")}>FA</NavItem>*/}
                            {/*}*/}
                            {/*{global.config.LOCALE === "en" ?*/}
                                {/*<NavItem eventKey={2} onClick={() => this.props.changeLang("en")} style={{"backgroundColor": "rgb(75, 124, 202)"}}>EN</NavItem> :*/}
                                {/*<NavItem eventKey={2} onClick={() => this.props.changeLang("en")}>EN</NavItem>*/}
                            {/*}*/}
                            {/*{global.config.LOCALE === "ar" ?*/}
                                {/*<NavItem eventKey={3} onClick={() => this.props.changeLang("ar")} style={{"backgroundColor": "rgb(75, 124, 202)"}}>AR</NavItem> :*/}
                                {/*<NavItem eventKey={3} onClick={() => this.props.changeLang("ar")}>AR</NavItem>*/}
                            {/*}*/}
                            {isAuthenticated ? <NavItem eventKey={6} onClick={this.props.logout()}>خروج</NavItem> : <NavItem eventKey={6} href="/login">ورود</NavItem>}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}