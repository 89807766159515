import React, { Component } from 'react';
import {Button, Checkbox, Alert, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import xhr from "../components/xhr";
import moment from 'moment-jalaali';
import {DatePicker} from "react-advance-jalaali-datepicker";

const entity = "کاربر";
const url = 'users';

export default class User extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {
                code: "+98"
            },
            message: null,
            submitLoading: false
        };
    }

    componentWillMount(){
        if(this.id) {
            new xhr(this, url, this.id).GetOne((item) => {
                this.setState({item});
            });
        }
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let item = this.state.item;
        while(item.mobile.substring(0, 1) === "0"){
            item.mobile = item.mobile.substring(1);
        }
        if(this.id){
            let data = item;
            data.id = this.id;
            if(!item.role) data.role = "user";
            new xhr(this, url, data).Put((response) => {
                if(response.status){
                    window.location = '/' + url;
                }
            });
        }else{
            item.role = "user";
            new xhr(this, url, item).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }
        setTimeout(() => {
            this.setState({
                message: null
            });
        }, 3000);
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        let value = target.value;
        if(target.type === "checkbox"){
            value = target.checked;
        }
        item[target.name] = value;
        this.setState({item});
    }

    handleUploadPic(event){
        let data = new FormData();
        data.append('image', event.target.files[0]);
        data.append('type', url);
        new xhr(this, 'upload', data).Upload(link => {
            if(link){
                let item = this.state.item;
                item.pic = link;
                this.setState(item);
            }
        });
    }

    handleRemovePic() {
        let item = this.state.item;
        item.pic = '';
        this.setState(item);
    }

    handleUploadCover(event){
        let data = new FormData();
        data.append('image', event.target.files[0]);
        data.append('type', url);
        new xhr(this, 'upload', data).Upload(link => {
            if(link){
                let item = this.state.item;
                item.cover = link;
                this.setState(item);
            }
        });
    }

    handleRemoveCover() {
        let item = this.state.item;
        item.cover = '';
        this.setState(item);
    }

    DatePickerInput(props) {
        return <input className="form-control" {...props} ></input>;
    }

    changeBD(unix){
        let item = this.state.item;
        item.b_date = moment.unix(unix).format("YYYY/M/D HH:mm:ss");
        this.setState(item);
    }

    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    <FormGroup>
                        <ControlLabel>تصویر پروفایل</ControlLabel>
                        <FormControl
                            name="pic"
                            type="file"
                            onChange={this.handleUploadPic.bind(this)}
                        />
                        {item.pic &&
                        <div>
                            <img src={item.pic} className="icon"/>
                            <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemovePic()}>×</Button>
                        </div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تصویر کاور</ControlLabel>
                        <FormControl
                            name="cover"
                            type="file"
                            onChange={this.handleUploadCover.bind(this)}
                        />
                        {item.cover &&
                        <div>
                            <img src={item.cover} className="icon"/>
                            <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemoveCover()}>×</Button>
                        </div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نام</ControlLabel>
                        <FormControl
                            type="text"
                            name="name"
                            value={item.name}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نام خانوادگی</ControlLabel> <span style={{color: "red"}}>*</span>
                        <FormControl
                            type="text"
                            name="family"
                            value={item.family}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>کد کشور</ControlLabel> <span style={{color: "red"}}>*</span>
                        <FormControl
                            type="text"
                            name="code"
                            value={item.code}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>موبایل</ControlLabel> <span style={{color: "red"}}>*</span>
                        <FormControl
                            type="text"
                            name="mobile"
                            value={item.mobile}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>رمز عبور</ControlLabel> <span style={{color: "red"}}>*</span>
                        <FormControl
                            type="password"
                            name="password"
                            value={item.password}
                            autoComplete="new-password"
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>جنسیت</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="gender"
                            value={item.gender}
                            onChange={this.handleChange.bind(this)}
                        >
                            <option value={1}>مرد</option>
                            <option value={0}>زن</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تاریخ تولد</ControlLabel>
                        {item.b_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeBD.bind(this)}
                            id="bDatePicker"
                            preSelected={item.b_date ? moment(item.b_date, "YYYY/M/D HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                        />
                        }
                        {!item.b_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeBD.bind(this)}
                            id="bDatePicker"
                            preSelected={""}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>بیوگرافی</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            name="bio"
                            value={item.bio}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox name="confirmed" checked={item.confirmed} onChange={this.handleChange.bind(this)}>تأیید شده</Checkbox>
                    </FormGroup>
                    <button disabled={submitLoading} className="btn btn-primary">{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}</button>
                </form>
                <p>{' '}</p>
            </div>
        );
    }
}