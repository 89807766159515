import React, { Component } from 'react';
import {FormGroup, FormControl, Button, ControlLabel, Alert} from 'react-bootstrap';
import xhr from "../components/xhr";

export default class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            item: {
                code: '+98',
                mobile: '',
                password: '',
                version: 'v2.1'
            },
            submitLoading: false
        };
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        let target = e.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({submitLoading: true});
        let data = this.state.item;
        new xhr(this, 'login', data, 1, '', 'v2.1').Post(response => {
            if(response.status){
                localStorage.setItem('token', response.token);
                global.config.TOKEN = response.token;
                let userId = response.data.item.id;
                localStorage.setItem('user', userId);
                new xhr(this, 'services', 'conditions=' + JSON.stringify({"user_id": userId, service_type: "complex"})).GetManyPure(service_response => {
                    console.log({service_response});
                    this.setState({submitLoading: false});
                    let list = service_response.data.list;
                    if(list[0]){
                        global.config.COMPLEX_ID = list[0].id;
                        localStorage.setItem('complex', list[0].id);
                        // لاگین مهمان برای دریافت توکن گست
                        new xhr(this, 'login', {
                            code: '+98',
                            mobile: 'guest',
                            password: '1'
                        }, 1, '', 'v2.1').Post(response => {
                            if(response.status){
                                localStorage.setItem('guest_token', response.token);
                                window.location = "/";
                            }else{
                                this.setState({
                                    message: <Alert bsStyle="danger">در فرآیند ورود مشکلی به وجود آمد، لطفاً با پشتیبانی فنی میز تماس بگیرید.</Alert>,
                                    submitLoading: false
                                });
                            }
                        });
                    }else{
                        this.setState({
                            message: <Alert bsStyle="danger">شما مدیر هیچ مجتمعی نیستید.</Alert>,
                            submitLoading: false
                        });
                    }
                });
            }else{
                this.setState({
                    message: <Alert bsStyle="danger">{response.note}</Alert>,
                    submitLoading: false
                });
            }
        });
    }

    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div className="login-box">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    <FormGroup>
                        <ControlLabel>نام کاربری</ControlLabel>
                        <FormControl
                            type="text"
                            name="mobile"
                            value={item.mobile}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>رمز عبور</ControlLabel>
                        <FormControl
                            type="password"
                            name="password"
                            value={item.password}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <Button type="submit" bsStyle="primary" disabled={submitLoading}>{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ورود'}</Button>
                </form>
            </div>
        );
    }
}