import React, { Component } from 'react';
import {Button, FormGroup, FormControl} from "react-bootstrap";
import xhr from "../components/xhr";
import moment from 'moment-jalaali';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const entities = 'کاربران';
const entity = 'کاربر';
const url = 'users';

export default class Users extends Component {

    constructor(props){
        super(props);
        this.state = {
            rows: null,
            referrers: [],
            photoIndex: 0,
            isOpen: false,
            search: '',
            page: 1,
            pagination: null
        };
        this.showPic = this.showPic.bind(this);
    }

    componentDidMount(){
        this.getRows();
    }

    getRows = (conditions = '', pageNumber = 1) => {
        if(conditions) conditions += "&";
        new xhr(this, url, conditions + 'sort=' + JSON.stringify({"_id": -1}), pageNumber).GetMany((list, pagination) => {
            let rows = [];
            list.map(item => {
                item.b_date = item.b_date ? moment(item.b_date, "YYYY/M/D HH:mm:ss").format("jYYYY/jMM/jDD") : '';
                item.full_name = item.name;
                if(item.family){
                    if(item.full_name){
                        item.full_name += " " + item.family;
                    }else{
                        item.full_name = item.family;
                    }
                }
                if(item.gender === 1){
                    item.gender = "مرد";
                }
                if(item.gender === 0){
                    item.gender = "زن";
                }
                item.referer && this.getReferer(item.referer);
                rows.push(item);
            });
            this.setState({rows, pagination});
        });
    };

    getReferer = (code) => {
        new xhr(this, url, "conditions=" + JSON.stringify({ref_code: code})).GetManyPure(response => {
            if(response.status){
                let list = response.data.list;
                if(list[0]){
                    let referrers = this.state.referrers;
                    referrers[code] = list[0].name + " " + list[0].family;
                    this.setState(referrers);
                }
            }
        });
    };

    remove(id, e){
        e.preventDefault();
        let ans = window.confirm('آیا مطمئنید که می خواهید این مورد را حذف کنید؟');
        if(!ans) return;
        let rows = this.state.rows;
        rows.map((row) => {
            if(row.id === id){
                row.removeIcon = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;
                row.onClick = (e) => {e.preventDefault()};
            }
        });
        this.setState({rows});
        new xhr(this, url, id).Delete((response) => {
            if(response.status) {
                let newRows = [];
                rows.map((row) => {
                    if (row.id !== id) {
                        newRows.push(row);
                    }
                });
                this.setState({rows: newRows});
            }
        });
    }

    showPic(pic){
        this.image = pic;
        this.setState({isOpen: true})
    }

    handleChange(e){
        let target = e.target;
        this.setState({search: target.value});
    }

    handleSearch(){
        let search = this.state.search;
        let conditions = {
            $or: [
                {name: {$regex: ".*" + search + ".*"}},
                {family: {$regex: ".*" + search + ".*"}},
                {mobile: {$regex: ".*" + search + ".*"}},
                {email: {$regex: ".*" + search + ".*"}},
            ]
        };
        let data = "conditions=" + JSON.stringify(conditions);
        this.getRows(data);
    }

    render(){
        const {rows, referrers, isOpen, search} = this.state;
        return (
            <div>
                <h2>{entities}</h2>
                <FormGroup>
                    <FormControl
                        type="text"
                        name="search"
                        value={search}
                        placeholder="جستجو"
                        onChange={this.handleChange.bind(this)}
                    />
                </FormGroup>
                <Button bsStyle="info" onClick={this.handleSearch.bind(this)}>جستجو</Button>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>تصویر</th>
                                <th>نام</th>
                                <th>موبایل</th>
                                <th>کد فعالسازی</th>
                                <th>جنسیت</th>
                                <th>تاریخ تولد</th>
                                <th>سکه ها</th>
                                <th>ایمیل</th>
                                <th>معرف</th>
                                <th>عملیات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows && rows.map((row) => <tr key={row.id}>
                                <td>{row.pic && <img src={row.pic} className="small-icon" onClick={() => this.showPic(row.pic)} />}</td>
                                <td>{row.full_name}</td>
                                <td>{row.mobile}</td>
                                <td>{row.activation_code}</td>
                                <td>{row.gender}</td>
                                <td>{row.b_date}</td>
                                <td>{row.coins}</td>
                                <td>{row.email}</td>
                                <td>{row.referer && referrers && referrers[row.referer]}</td>
                                <td>
                                    <a href={url + "/edit/" + row.id}>{row.editIcon}</a>
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                    <a href={url} onClick={(e) => {this.remove(row.id, e)}}>{row.removeIcon}</a>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                {this.state.pagination}
                {isOpen && (
                    <Lightbox
                        mainSrc={this.image}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
            </div>
        );
    }
}