import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';
import Header from "./pages/parts/Header";
import Dashboard from "./pages/Dashboard";
import Sidebar from "./pages/parts/Sidebar";
import Login from "./pages/Login";
import PrivateRoute from './components/PrivateRoute';
import Users from "./pages/Users";
import User from "./pages/User";
import Businesses from "./pages/Businesses";
import Business from "./pages/Business";
import ComplexFloors from "./pages/ComplexFloors";
import ComplexFloor from "./pages/ComplexFloor";
import AddMobile from "./pages/AddMobile";
import AddConfirmUser from "./pages/AddConfirmUser";
import AddConfirmBusiness from "./pages/AddConfirmBusiness";
import AddProfile from "./pages/AddProfile";
import AddBusinessInfo from "./pages/AddBusinessInfo";
import AddFloorUnit from "./pages/AddFloorUnit";
import AddUserBusinesses from "./pages/AddUserBusinesses";

export default class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            isAuthenticated: true
        };
        this.handleChangeLang = this.handleChangeLang.bind(this);
    }

    componentWillMount(){
        global.config.TOKEN = localStorage.getItem('token');
        if(!global.config.TOKEN){
            this.setState({isAuthenticated: false});
        }
        global.config.LOCALE = localStorage.getItem('locale');
        if(!global.config.LOCALE){
            global.config.LOCALE = "fa";
        }
        global.config.COMPLEX_ID = localStorage.getItem('complex');
        global.config.GUEST_TOKEN = localStorage.getItem('guest_token');
    }

    handleLogout(){
        localStorage.removeItem('token');
        localStorage.removeItem('complex');
        localStorage.removeItem('user');
        localStorage.removeItem('guest_token');
        global.config.TOKEN = '';
        window.location.reload();
    }

    handleChangeLang(lang){
        localStorage.setItem('locale', lang);
        window.location.reload();
    }

    render(){
        return (
            <div style={{height: '100%'}}>
                <Header changeLang={this.handleChangeLang} logout={() => this.handleLogout.bind(this)} auth={this.state.isAuthenticated}/>
                <div className="container-fluid">
                    <div className="row">
                        {this.state.isAuthenticated &&
                            <div className="col-md-2">
                                <Sidebar/>
                            </div>
                        }
                        <div className={this.state.isAuthenticated ? "col-md-10" : "col-md-12"}>
                            <Switch>
                                <PrivateRoute exact path="/" component={Dashboard} auth={this.state.isAuthenticated}/>
                                <Route exact path="/login" component={Login} />
                                <PrivateRoute exact path="/users" component={Users} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/users/add" component={User} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/users/edit/:id" component={User} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/businesses" component={Businesses} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/businesses/add" component={Business} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/businesses/edit/:id" component={Business} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/complex_floors" component={ComplexFloors} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/complex_floors/add" component={ComplexFloor} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/complex_floors/edit/:id" component={ComplexFloor} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/add/mobile" component={AddMobile} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/add/confirm_user" component={AddConfirmUser} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/add/confirm_business/:id" component={AddConfirmBusiness} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/add/profile" component={AddProfile} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/add/business" component={AddBusinessInfo} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/add/floor_unit" component={AddFloorUnit} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/add/user_businesses" component={AddUserBusinesses} auth={this.state.isAuthenticated}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}