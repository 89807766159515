import React, { Component } from 'react';
import Button from "react-bootstrap/es/Button";
import xhr from "../components/xhr";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const entities = 'طبقات';
const entity = 'طبقه';
const url = 'complex_floors';

export default class ComplexFloors extends Component {

    constructor(props){
        super(props);
        this.id = global.config.COMPLEX_ID;
        if(!this.id){
            window.location = "/complexes";
        }
        this.state = {
            rows: null,
            isOpen: false,
            page: 1,
            pagination: null,
            complex_name: ''
        };
        this.showPic = this.showPic.bind(this);
    }

    componentDidMount(){
        this.getRows();

        new xhr(this, 'services', this.id).GetOne(item => {
            this.setState({complex_name: item.title})
        });
    }

    getRows = (pageNumber = 1) => {
        new xhr(this, url, 'conditions=' + JSON.stringify({complex_id: global.config.COMPLEX_ID}) + '&sort=' + JSON.stringify({"order": -1}), pageNumber).GetMany((rows, pagination) => {
            this.setState({rows, pagination, page: pageNumber});
        });
    };

    remove(id, e){
        e.preventDefault();
        let ans = window.confirm('آیا مطمئنید که می خواهید این مورد را حذف کنید؟');
        if(!ans) return;
        let rows = this.state.rows;
        rows.map((row) => {
            if(row.id === id){
                row.removeIcon = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;
                row.onClick = (e) => {e.preventDefault()};
            }
        });
        this.setState({rows});
        new xhr(this, url, id).Delete((response) => {
            if(response.status) {
                let newRows = [];
                rows.map((row) => {
                    if (row.id !== id) {
                        newRows.push(row);
                    }
                });
                this.setState({rows: newRows});
            }
        });
    }

    showPic(pic){
        this.image = pic;
        this.setState({isOpen: true})
    }

    render(){
        const {isOpen, complex_name} = this.state;
        return (
            <div>
                <h2>{entities} {complex_name}</h2>
                <Button bsStyle="primary" href={"/" + url + "/add"}>افزودن {entity} جدید</Button>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>تصویر</th>
                            <th>نام</th>
                            <th>ترتیب</th>
                            <th>عملیات</th>
                        </tr>
                        </thead>
                        <tbody>
                            {this.state.rows && this.state.rows.map((row) =>
                                <tr key={row.id}>
                                    <td>{row.image && <img src={row.image} className="small-icon" onClick={() => this.showPic(row.image)} />}</td>
                                    <td>{row.name}</td>
                                    <td>{row.order}</td>
                                    <td>
                                        <a href={"/" + url + "/edit/" + this.id + "/" + row.id}>{row.editIcon}</a>
                                        &nbsp; &nbsp; &nbsp; &nbsp;
                                        <a href={url} onClick={(e) => {this.remove(row.id, e)}}>{row.removeIcon}</a>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {this.state.pagination}
                {isOpen && (
                    <Lightbox
                        mainSrc={this.image}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
            </div>
        );
    }
}