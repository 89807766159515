import React, { Component } from 'react';
import {Button, FormGroup, FormControl, Panel} from "react-bootstrap";
import xhr from "../components/xhr";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import PickUser from "../components/PickUser";
import SelectSearch from 'react-select-search';
import PickComplex from "../components/PickComplex";

const entities = 'کسب و کارها';
const url = 'services';

export default class Businesses extends Component {

    constructor(props){
        super(props);
        this.state = {
            rows: null,
            photoIndex: 0,
            isOpen: false,
            page: 1,
            pagination: null,
            search: {},
            search_items: {},
            categories: [],
            confirm_loading: [],
            cities: [{name: '', value: ''}],
            complexes: [{name: '', value: ''}],
            show_pick_complex: false,
            business_id: null,
            business_title: null,
            business_category_id: null,
            details: {},
            detailsId: []
        };
        this.showPic = this.showPic.bind(this);
        this.confirm = this.confirm.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.getRows = this.getRows.bind(this);
        this.removeSearchItem = this.removeSearchItem.bind(this);
        this.handleShowComplexes = this.handleShowComplexes.bind(this);
        this.handleHideComplexes = this.handleHideComplexes.bind(this);
        this.getDetails = this.getDetails.bind(this);
    }

    componentDidMount(){
        this.getRows();

        new xhr(this, 'categories', 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}), -1).GetManyPure(response => {
            if(response.status){
                let list = response.data.list;
                let categories = [];
                for(let i = 0; i < list.length; i++){
                    categories.push({name: list[i].name, value: list[i].id});
                }
                this.setState({categories});
            }
        });

        new xhr(this, 'cities', 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}), -1).GetManyPure(response => {
            if(response.status) {
                let list = response.data.list;
                let cities = [];
                for(let i = 0; i < list.length; i++){
                    cities.push({name: list[i].name, value: list[i].id});
                }
                this.setState({cities});
            }
        });

        new xhr(this, 'services', 'conditions=' + JSON.stringify({service_type: "complex"}), -1).GetManyPure(response => {
            if(response.status) {
                let list = response.data.list;
                let complexes = [];
                for(let i = 0; i < list.length; i++){
                    complexes.push({name: list[i].title + " - " + list[i].city_name, value: list[i].id});
                }
                this.setState({complexes});
            }
        });
    }

    getRows = (pageNumber = 1, condition = '', callback) => {
        let cond = this.checkSearch();
        if(cond && Object.keys(cond).length !== 0){
            condition = "conditions=" + JSON.stringify(cond);
        }
        condition += JSON.stringify({parent_id: global.config.COMPLEX_ID}) + '&';
        new xhr(this, url, condition + 'sort=' + JSON.stringify({"_id": -1}), pageNumber).GetMany((rows, pagination) => {
            this.setState({rows, pagination, page: pageNumber}, callback);
            for(let i = 0; i < rows.length; i++){
                this.getDetails(rows[i].id);
            }
        });
    };

    getDetails(id){
        new xhr(this, 'complex_details', "conditions=" + JSON.stringify({
            complex_id: global.config.COMPLEX_ID,
            service_id: id
        })).GetManyPure(response => {
            let list = response.data.list;
            let details = this.state.details;
            let detailsId = this.state.detailsId;
            if(list[0]){
                details[id] = 'طبقه ' + list[0].floor + ' واحد ' + list[0].number;
                detailsId[id] = list[0].id;
                this.setState(details);
            }
        });
    }

    remove(id, e){
        e.preventDefault();
        let ans = window.confirm('آیا مطمئن هستید می خواهید این کسب و کار را از مجموعه خود حذف کنید؟');
        if(!ans) return;
        let rows = this.state.rows;
        rows.map((row) => {
            if(row.id === id){
                row.removeIcon = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;
                row.onClick = (e) => {e.preventDefault()};
            }
        });
        this.setState({rows});
        new xhr(this, 'complex_details', id).Delete(() => {
            let newRows = [];
            rows.map((row) => {
                if (row.id !== id) {
                    newRows.push(row);
                }
            });
            this.setState({rows: newRows});
        });
    }

    showPic(pic){
        this.image = pic;
        this.setState({isOpen: true})
    }

    confirm(id, conf){
        let confirm_loading = [];
        confirm_loading[id] = true;
        this.setState({confirm_loading});
        let data = {
            id: id,
            confirmed: conf
        };
        new xhr(this, url, data).Put((response) => {
            if(response.status){
                confirm_loading[id] = false;
                this.getRows(this.state.page, '', () => {
                    this.setState({confirm_loading});
                });
            }
        });
    }

    handleChangeSearch(e){
        let target = e.target;
        let search = this.state.search;
        search[target.name] = target.value;

        let search_items = this.state.search_items;
        search_items[target.name] = [target.name, target.value];

        this.setState({search, search_items});
    }

    handleChangeUser(id, name){
        if(id){
            let search = this.state.search;
            search.user_id = id;

            let search_items = this.state.search_items;
            search_items.user = ["user", name];

            this.setState({search, search_items});
        }
    }

    handleChangeCategory(value){
        if(value){
            let search = this.state.search;
            search.category_name = value.name;
            search.category_id = value.value;

            let search_items = this.state.search_items;
            search_items.category = ["category", value.name];

            this.setState({search, search_items});
        }
    }

    handleChangeCity(value){
        if(value){
            let search = this.state.search;
            search.city_name = value.name;
            search.city_id = value.value;

            let search_items = this.state.search_items;
            search_items.city = ["city", value.name];

            this.setState({search, search_items});
        }
    }

    checkSearch(){
        let search = this.state.search;
        let cond = {};
        if(search.phrase){
            cond = {
                $or: [
                    {title: {$regex: ".*" + search.phrase + ".*"}},
                    {description: {$regex: ".*" + search.phrase + ".*"}},
                ]
            };
        }
        if(search.category_id){
            cond.category_id = search.category_id;
        }
        if(search.city_id){
            cond.city_id = search.city_id;
        }
        if(search.user_id){
            cond.user_id = search.user_id;
        }
        return cond;
    }

    removeSearchItem(key){
        console.log(key);
        let search = this.state.search;
        let search_items = this.state.search_items;
        delete search_items[key];
        switch (key) {
            case "category":
                delete search["category_name"];
                delete search["category_id"];
                break;
            case "city":
                delete search["city_name"];
                delete search["city_id"];
                break;
            case "complex":
                delete search["complex_name"];
                delete search["complex_id"];
                break;
            case "user":
                delete search["user_id"];
                break;
            case "phrase":
                search.phrase = '';
                break;
            default:
                break;
        }
        this.setState({search, search_items});
    }

    handleShowComplexes(e, id, title, cat_id){
        e.preventDefault();
        this.setState({
            show_pick_complex: true,
            business_id: id,
            business_title: title,
            business_category_id: cat_id
        });
    }

    handleHideComplexes(){
        this.setState({show_pick_complex: false});
    }

    render(){
        const {isOpen, search, search_items, categories, cities, show_pick_complex, business_id, business_title, business_category_id, details, detailsId} = this.state;
        return (
            <div>
                <h2>{entities}</h2>
                <PickComplex show={show_pick_complex} hide={this.handleHideComplexes} child={{id: business_id, title: business_title, category_id: business_category_id}}/>
                <Panel id="search-panel">
                    <Panel.Heading>
                        <Panel.Title toggle>
                            جستجو
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <FormGroup>
                                <FormControl
                                    type="text"
                                    name="phrase"
                                    placeholder="جستجوی عبارت در عنوان و توضیحات"
                                    value={search.phrase}
                                    onChange={this.handleChangeSearch.bind(this)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <SelectSearch
                                    options={categories}
                                    value={search.category_name}
                                    name="category_id"
                                    placeholder="دسته بندی را انتخاب کنید"
                                    onChange={this.handleChangeCategory.bind(this)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <SelectSearch
                                    options={cities}
                                    value={search.city_name}
                                    name="city_id"
                                    placeholder="شهر را انتخاب کنید"
                                    onChange={this.handleChangeCity.bind(this)}
                                />
                            </FormGroup>
                            <PickUser changeUser={this.handleChangeUser} />
                            <Button bsStyle="info" onClick={() => this.getRows(1)}>جستجو</Button>
                            <div>
                                {Object.values(search_items).map(item =>
                                    <div className="search-item">{item[1]} <Button bsStyle="danger" bsSize="xsmall" onClick={() => this.removeSearchItem(item[0])}>×</Button></div>
                                )}
                            </div>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>تصویر</th>
                                <th>عنوان</th>
                                <th>دسته بندی</th>
                                <th>کاربر</th>
                                <th>توضیحات</th>
                                <th>مکان</th>
                                <th>تماس</th>
                                <th>عملیات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.rows ? this.state.rows.map((row) => <tr key={row.id}>
                                <td>{row.images && row.images[0] && <img src={row.images[0]} className="small-icon" onClick={() => this.showPic(row.images[0])} />}</td>
                                <td>
                                    {row.title}
                                    {row.parent_id && details[row.id] ? <div className="complex-tag link" onClick={(e) => this.handleShowComplexes(e, row.id, row.title, row.category_id)}>{details[row.id]}</div> : ""}
                                </td>
                                <td>{row.category_name}</td>
                                <td>{row.user.name} {row.user.family}</td>
                                <td title={row.description}>{row.description && row.description.length > 50 ? row.description.substr(0, 50) + "..." : row.description}</td>
                                <td>{row.city_name}<br/>{row.address}</td>
                                <td style={{"direction": "ltr"}}>
                                    {row.code}-{row.phone} -&nbsp;
                                    {row.fax} -&nbsp;
                                    {row.email} -&nbsp;
                                    {row.web}
                                </td>
                                <td>
                                    {row.id === global.config.COMPLEX_ID &&
                                        <span>
                                            <a title="ویرایش" href={"businesses/edit/" + row.id}>{row.editIcon}</a>
                                            &nbsp; &nbsp; &nbsp; &nbsp;
                                        </span>
                                    }
                                    {row.service_type !== "complex" && (
                                        <span>
                                            <a title="افزودن به مجتمع تجاری" href={url} onClick={(e) => this.handleShowComplexes(e, row.id, row.title, row.category_id)}><span className="glyphicon glyphicon-plus-sign" /></a>
                                            &nbsp; &nbsp; &nbsp; &nbsp;
                                        </span>
                                    )}
                                    {row.id !== global.config.COMPLEX_ID &&
                                        <a title="حذف" href={url} onClick={(e) => {
                                            this.remove(detailsId[row.id], e)
                                        }}>{row.removeIcon}</a>
                                    }
                                </td>
                            </tr>)
                                :
                                <tr><td colSpan={8}><span className="glyphicon glyphicon-repeat fast-right-spinner"/> در حال دریافت اطلاعات ...</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                {this.state.pagination}
                {isOpen && (
                    <Lightbox
                        mainSrc={this.image}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
            </div>
        );
    }
}