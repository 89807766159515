import React, { Component } from 'react';
import xhr from '../components/xhr';
import Button from "react-bootstrap/es/Button";

export default class AddUserBusinesses extends Component {
    constructor(props){
        super(props);
        this.state = {
            rows: null
        };
        this.handlePick = this.handlePick.bind(this);
    }

    componentDidMount(){
        this.userId = localStorage.getItem('_add_user');
        this.getRows();
    }

    getRows = (pageNumber = 1) => {
        let condition = "conditions=" + JSON.stringify({user_id: this.userId});
        new xhr(this, 'services', condition + '&sort=' + JSON.stringify({"_id": -1}), pageNumber, global.config.GUEST_TOKEN).GetMany((rows, pagination) => {
            this.setState({rows, pagination, page: pageNumber});
        });
    };

    handlePick(row){
        localStorage.setItem('_add_business_confirmed', row.id);
        window.location = '/add/floor_unit';
    }

    render(){
        const {rows, pagination} = this.state;
        return (
            <div>
                <h2>کسب و کارهای کاربر انتخاب شده</h2>
                <p>برای کاربر انتخاب شده {<strong style={{color:'blue'}}>{rows && rows[0] && rows[0].user.name + ' ' + rows[0].user.family}</strong>} کسب و کارهای زیر ثبت شده است، اگر واحدی که می خواهید اضافه کنید یکی از آنهاست آن را انتخاب کنید، در غیر اینصورت دکمه هیچکدام را در پایین صفحه بزنید.</p>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>تصویر</th>
                            <th>عنوان</th>
                            <th>دسته بندی</th>
                            <th>توضیحات</th>
                            <th>شهر</th>
                            <th>انتخاب</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows ? rows.map(row => <tr key={row.id}>
                            <td>{row.images && row.images[0] && <img src={row.images[0]} className='small-icon' />}</td>
                            <td>{row.title}</td>
                            <td>{row.category_name}</td>
                            <td title={row.description}>{row.description.length > 50 ? row.description.substr(0, 50) + "..." : row.description}</td>
                            <td>{row.city_name}</td>
                            <td>
                                <Button bsStyle="success" onClick={() => this.handlePick(row)}>انتخاب</Button>
                            </td>
                        </tr>)
                            :
                            <tr><td colSpan={6}><span className="glyphicon glyphicon-repeat fast-right-spinner"/> در حال دریافت اطلاعات ...</td></tr>
                        }
                        </tbody>
                    </table>
                </div>
                {pagination}
                <Button bsStyle="warning" onClick={() => window.location = "/add/business"}>هیچکدام</Button>
            </div>
        );
    }
}