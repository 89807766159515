import React, { Component } from 'react';
import xhr from "../components/xhr";
import {Button, Alert} from 'react-bootstrap';

export default class AddConfirmBusiness extends Component {
    constructor(props){
        super(props);
        this.state = {
            business: {},
            submitLoading: false,
            message: ''
        };
        this.handleAnswer = this.handleAnswer.bind(this);
    }

    componentDidMount(){
        this.id = this.props.match.params.id;
        new xhr(this, 'services', this.id, 1, global.config.GUEST_TOKEN).GetOne(item => {
            this.setState({business: item});
        });
    }

    handleAnswer(ans){
        let mobile = localStorage.getItem('_add_mobile');
        if(!mobile){
            window.location = '/add/mobile';
        }else{
            // ساخت کاربر با آن شماره
            let data = {
                family: 'کاربر میز',
                code: '+98',
                mobile: mobile,
                password: '123',
                role: 'user',
                confirmed: true,
                parent_id: localStorage.getItem("user")
            };
            new xhr(this, 'users', data).Post(response => {
                if(response.status){
                    let userId = response.data.item.id;
                    localStorage.setItem('_add_user', userId);
                    // اختصاص کسب و کار قدیمی به این کاربر
                    let data = {
                        service_id: this.id,
                        title: this.state.business.title,
                        user_id: userId
                    };
                    new xhr(this, 'claims', data).Post(response => {
                        if(response.status){
                            if(ans === "yes") {
                                localStorage.setItem("_add_business_confirmed", this.id);
                            }
                            // تکمیل اطلاعات کاربر اضافه شده
                            window.location = "/add/profile";
                        }else{
                            this.setState({
                                submitLoading: false,
                                message: <Alert bsStyle="danger">در فرآیند ایجاد کسب و کار مشکلی به وجود آمد، با پشتیبانی فنی تماس بگیرید.</Alert>
                            });
                        }
                    });
                }else{
                    this.setState({
                        submitLoading: false,
                        message: <Alert bsStyle="danger">در فرآیند ایجاد کسب و کار مشکلی به وجود آمد، با پشتیبانی فنی تماس بگیرید.</Alert>
                    });
                }
            });
        }
    }

    render(){
        const {business, submitLoading, message} = this.state;
        return (
            <div>
                {message}
                {business.images && business.images[0] && <img src={business.images[0]} className="icon" />}
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td>عنوان</td>
                            <td>{business.title}</td>
                        </tr>
                        <tr>
                            <td>توضیحات</td>
                            <td>{business.description}</td>
                        </tr>
                        <tr>
                            <td>تلفن</td>
                            <td>{business.phone}</td>
                        </tr>
                        <tr>
                            <td>آدرس</td>
                            <td>{business.address}</td>
                        </tr>
                    </tbody>
                </table>
                <Alert bsStyle="info">برای این شماره کسب و کار فوق تعریف شده است، آیا کسب و کار مدنظر شما همین است؟</Alert>
                <Button bsStyle="primary" onClick={() => this.handleAnswer("yes")} disabled={submitLoading}>{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'بلی'}</Button>
                {' '}
                <Button bsStyle="warning" onClick={() => this.handleAnswer("no")} disabled={submitLoading}>{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'خیر'}</Button>
                {' '}
                <Button bsStyle="danger" onClick={() => window.location = "/add/mobile"}>تغییر شماره موبایل</Button>
            </div>
        );
    }
}