import React, { Component } from 'react';
import {Button, Modal, FormGroup, ControlLabel, FormControl} from "react-bootstrap";
import xhr from "./xhr";

const url = 'users';

export default class PickUser extends Component {
    constructor(props){
        super(props);
        this.state = {
            rows: [],
            search: '',
            user: props.user,
            show: false
        };
        this.handleHide = this.handleHide.bind(this);
        this.handlePick = this.handlePick.bind(this);
    }

    componentDidMount(){
        this.getRows();
    }

    componentWillReceiveProps(props){
        this.setState({user: props.user});
    }

    getRows = (conditions = '', pageNumber = 1) => {
        if(conditions) conditions += "&";
        new xhr(this, url, conditions + 'sort=' + JSON.stringify({"_id": -1}), pageNumber).GetMany((list, pagination) => {
            let rows = [];
            list.map(item => {
                item.full_name = item.name;
                if(item.family){
                    if(item.full_name){
                        item.full_name += " " + item.family;
                    }else{
                        item.full_name = item.family;
                    }
                }
                if(item.gender === 1){
                    item.gender = "مرد";
                }
                if(item.gender === 0){
                    item.gender = "زن";
                }
                rows.push(item);
            });
            this.setState({rows, pagination});
        });
    };

    handleHide() {
        this.setState({ show: false });
    }

    handlePick(row){
        if(row){
            this.setState({
                user: {
                    id: row.row.id,
                    full_name: row.row.name + " " + row.row.family
                },
                show: false
            });
            this.props.changeUser(row.row.id, row.row.name + " " + row.row.family);
        }
    }

    handleChange(event){
        let target = event.target;
        this.setState({search: target.value});
    }

    handleSearch(pageNumber = 1){
        let search = this.state.search;
        new xhr(this, url, 'quick_search=' + JSON.stringify({title: search}), pageNumber).GetMany((list, pagination) => {
            this.setState({rows: list, pagination});
        });
    }

    showPic(pic){
        this.image = pic;
        this.setState({isOpen: true})
    }

    render(){
        const {user, search, rows, pagination} = this.state;

        return (
            <div>
                <Modal show={this.state.show} onHide={this.handleHide} bsSize="large" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>انتخاب کاربر</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <FormControl
                                type="text"
                                name="search"
                                value={search}
                                placeholder="جستجو"
                                onChange={this.handleChange.bind(this)}
                            />
                        </FormGroup>
                        <Button bsStyle="info" onClick={this.handleSearch.bind(this)}>جستجو</Button>
                        <hr/>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>تصویر</th>
                                    <th>نام</th>
                                    <th>موبایل</th>
                                    <th>جنسیت</th>
                                    <th>ایمیل</th>
                                    <th>انتخاب</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {rows && rows.map(row => <tr key={row.id}>
                                        <td>{row.pic && <img src={row.pic} className="small-icon" onClick={() => this.showPic(row.pic)} />}</td>
                                        <td>{row.full_name}</td>
                                        <td>{row.mobile}</td>
                                        <td>{row.gender}</td>
                                        <td>{row.email}</td>
                                        <td>
                                            <Button bsStyle="success" onClick={() => this.handlePick({row})}>انتخاب</Button>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                        {pagination}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.handleHide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                <Button bsStyle="info" onClick={() => this.setState({ show: true })}>انتخاب کاربر</Button>
                <div className="show_user">
                    {user && <a href={'/users/edit/' + user.id} target="_blank" rel="noopener noreferrer">{user.full_name}</a>}
                </div>
            </div>
        );
    }
}