import React, { Component } from 'react';

export default class Dashboard extends Component {
    render(){
        return (
            <div>
                کنترل پنل مدیریت مجتمع تجاری
            </div>
        );
    }
}