import React, { Component } from 'react';
import Button from "react-bootstrap/es/Button";
import xhr from "../components/xhr";
import FormGroup from "react-bootstrap/es/FormGroup";
import ControlLabel from "react-bootstrap/es/ControlLabel";
import FormControl from "react-bootstrap/es/FormControl";
import {Alert} from "react-bootstrap";

export default class AddFloorUnit extends Component {
    constructor(props){
        super(props);
        this.state = {
            item: {
                complex_id: this.complexId,
                service_id: this.businessId
            },
            complex_user_id: localStorage.getItem('user'),
            floors: [],
            message: null,
            submitLoading: false
        };
        this.handleAddToComplex = this.handleAddToComplex.bind(this);
    }

    componentDidMount(){
        this.complexId = global.config.COMPLEX_ID;
        this.businessId = localStorage.getItem('_add_business_confirmed');
        this.setState({
            item: {
                complex_id: this.complexId,
                service_id: this.businessId
            }
        });

        new xhr(this, 'complex_floors', "conditions=" + JSON.stringify({complex_id: this.complexId}), -1).GetManyPure(response => {
            let list = response.data.list;
            let floors = [];
            for(let i = 0; i < list.length; i++){
                floors.push(<option key={list[i].id} value={list[i].name}>{list[i].name}</option>);
            }
            let item = this.state.item;
            if(list[0]) {
                item.floor = list[0].name;
            }
            this.setState({floors, item});
        });

        new xhr(this, 'complex_details', "conditions=" + JSON.stringify({
            complex_id: this.complexId,
            service_id: this.businessId
        })).GetManyPure(response => {
            let list = response.data.list;
            if(list.length > 0){
                let item = this.state.item;
                item.title = list[0].title;
                item.floor = list[0].floor;
                item.number = list[0].number;
                this.setState(item);
            }else{
                new xhr(this, 'services', this.businessId).GetOne(business => {
                    let item = this.state.item;
                    item.title = business.title;
                    item.category_id = business.category_id;
                    this.setState({item});
                })
            }
        });
    }

    handleChangeDetails(e){
        let target = e.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState(item);
    }

    handleAddToComplex(e){
        e.preventDefault();
        new xhr(this, 'complex_details', "conditions=" + JSON.stringify({service_id: this.state.item.service_id})).GetManyPure(response => {
            let list = response.data.list;
            if(list.length > 0){
                let data = this.state.item;
                data.id = list[0].id;
                new xhr(this, 'complex_details', data).Put(response => {
                    if(response.status){
                        this.setState({
                            message: <Alert bsStyle="success">کسب و کار مورد نظر با موفقیت ثبت شد و پس از تأیید مالک به پنل شما افزوده خواهد شد.</Alert>,
                            submitLoading: false,
                        });
                    }else{
                        if(response.code === 544){
                            this.setState({
                                message: <Alert bsStyle="success">کسب و کار مورد نظر با موفقیت ثبت شد و پس از تأیید مالک به پنل شما افزوده خواهد شد.</Alert>,
                                submitLoading: false,
                            });
                        }else{
                            this.setState({
                                message: <Alert bsStyle="danger">در فرآیند ایجاد کسب و کار مشکلی به وجود آمد، با پشتیبانی فنی تماس بگیرید.</Alert>,
                                submitLoading: false,
                            });
                        }
                    }
                });
            }else{
                new xhr(this, 'complex_details', this.state.item).Post(response => {
                    if(response.status){
                        this.setState({
                            message: <Alert bsStyle="success">کسب و کار مورد نظر با موفقیت ثبت شد و پس از تأیید مالک به پنل شما افزوده خواهد شد.</Alert>,
                            submitLoading: false,
                        });
                    }else{
                        this.setState({
                            message: <Alert bsStyle="danger">در فرآیند ایجاد کسب و کار مشکلی به وجود آمد، با پشتیبانی فنی تماس بگیرید.</Alert>,
                            submitLoading: false,
                        });
                    }
                });
            }
        });
    }

    render(){
        const {item, message, submitLoading, floors} = this.state;

        return (
            <div>
                {message}
                <FormGroup>
                    <ControlLabel>کسب و کار</ControlLabel>
                    <FormControl.Static>{item.title}</FormControl.Static>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>عنوان</ControlLabel>
                    <FormControl
                        type="text"
                        name="title"
                        value={item.title}
                        onChange={this.handleChangeDetails.bind(this)}
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>طبقه</ControlLabel>
                    <FormControl
                        componentClass="select"
                        name="floor"
                        value={item.floor}
                        onChange={this.handleChangeDetails.bind(this)}
                    >
                        {floors}
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>واحد</ControlLabel>
                    <FormControl
                        type="text"
                        name="number"
                        value={item.number}
                        placeholder="مثلاً 204"
                        onChange={this.handleChangeDetails.bind(this)}
                    />
                </FormGroup>
                <Button
                    disabled={submitLoading}
                    bsStyle="primary"
                    onClick={(e) => this.handleAddToComplex(e)}
                >
                    {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'اختصاص'}
                </Button>
            </div>
        );
    }
}