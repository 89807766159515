import axios from "axios";
import Pagination from "react-bootstrap/es/Pagination";
import React from "react";

const PerPage = 10;

export default class xhr {
    constructor(parent, url, data = '', page = 1, token = '', version = 'v2') {
        this.parent = parent;
        this.url = version + '/' + this.convertLetters(this.convertNumbers(url));
        let newData = data;
        if(typeof data === 'object') {
            for (let [key, value] of Object.entries(data)) {
                if (value === null) {
                    delete newData[key];
                    continue;
                }
                if(typeof value === "string") {
                    value = this.convertLetters(this.convertNumbers(value));
                }
                newData[key] = value;
            }
        }else{
            newData = this.convertLetters(this.convertNumbers(data));
        }
        this.data = newData;
        this.page = page;
        this.locale = global.config.LOCALE;
        if(token){
            this.token = token;
        }else{
            this.token = global.config.TOKEN;
        }
    }

    convertLetters(value){
        let arabicLetters = ["ي", "ك"];
        let farsiLetters = ["ی", "ک"];
        let regex = '';
        for(let i = 0; i < 2; i++){
            regex = new RegExp(arabicLetters[i], "g");
            value = value.replace(regex, farsiLetters[i]);
        }
        return value;
    }

    convertNumbers(value){
        let farsiNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
        let englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        let regex = '';
        for(let i = 0; i < 10; i++){
            regex = new RegExp(farsiNumbers[i], "g");
            value = value.replace(regex, englishNumbers[i]);
        }
        return value;
    }

    handleExpiredToken(){
        localStorage.removeItem('token');
        global.config.TOKEN = '';
        window.location = '/login';
    }

    GetOne(callback){
        axios.get(global.config.BASE_URL + '/' + this.url + '/' + this.data +'?token=' + this.token + '&locale=' + this.locale).then(response => {
            if(response.status >= 200 && response.status < 300) {
                response.data.status = true;
                callback(response.data.data.item);
            }else{
                if(response.data.code === 401){
                    this.handleExpiredToken();
                }else {
                    console.error(response.data.note);
                }
            }
        });
    }

    GetManyPure(callback){
        if(this.data){
            this.data = '&' + this.data;
        }
        let url = this.url + '?token=' + this.token + '&locale=' + this.locale + this.data;
        if(this.page > 0){
            url += '&page_size=' + PerPage + '&page=' + this.page;
        }else{
            url += '&page_size=1000000&page=1';
        }
        axios.get(global.config.BASE_URL + '/' + url).then(response => {
            if(response.status >= 200 && response.status < 300) {
                response.data.status = true;
                callback(response.data);
            }else{
                if(response.data.code === 401){
                    this.handleExpiredToken();
                }else {
                    console.error(response.data.note);
                }
            }
        });
    }

    GetMany(callback){
        if(this.data){
            this.data = '&' + this.data;
        }
        let url = this.url + '?token=' + this.token + '&locale=' + this.locale + this.data;
        if(this.page > 0){
            url += '&page_size=' + PerPage + '&page=' + this.page;
        }else{
            url += '&page_size=1000000&page=1';
        }
        axios.get(global.config.BASE_URL + '/' + url).then(response => {
            if(response.status >= 200 && response.status < 300) {
                response.data.status = true;
                let list = response.data.data.list;
                let rows = [];
                list.map((item) => {
                    item.editIcon = <span className="glyphicon glyphicon-pencil"/>;
                    item.removeIcon = <span className="glyphicon glyphicon-trash"/>;
                    rows.push(item);
                });
                let items = [];
                if (response.data.data.count > PerPage) {
                    let pageCount = Math.ceil(response.data.data.count / PerPage);
                    let active = this.page;
                    if (pageCount <= 20) {
                        for (let i = 1; i <= pageCount; i++) {
                            items.push(
                                <Pagination.Item key={i} active={i === active}
                                                 onClick={() => this.parent.getRows(i)}>{i}</Pagination.Item>
                            );
                        }
                    } else {
                        if (active > 1) {
                            items.push(<Pagination.First key={1} onClick={() => this.parent.getRows(1)}/>);
                            items.push(<Pagination.Prev key={-1} onClick={() => this.parent.getRows(active - 1)}/>);
                        }
                        if (active > 3) items.push(<Pagination.Ellipsis key={-2}/>);
                        let start = active - 2;
                        if (start < 1) start = 1;
                        for (let i = start; i < active; i++) {
                            items.push(<Pagination.Item key={i} active={i === active}
                                                        onClick={() => this.parent.getRows(i)}>{i}</Pagination.Item>);
                        }
                        items.push(<Pagination.Item key={active} active={true}>{active}</Pagination.Item>);
                        let finish = active + 2;
                        if (finish > pageCount) finish = pageCount;
                        for (let i = active + 1; i <= finish; i++) {
                            items.push(<Pagination.Item key={i} active={i === active}
                                                        onClick={() => this.parent.getRows(i)}>{i}</Pagination.Item>);
                        }
                        if (active < pageCount - 2) items.push(<Pagination.Ellipsis key={-3}/>);
                        if (active < pageCount) {
                            items.push(<Pagination.Next key={-4} onClick={() => this.parent.getRows(active + 1)}/>);
                            items.push(<Pagination.Last key={-5} onClick={() => this.parent.getRows(pageCount)}/>);
                        }
                    }
                }
                let pagination = <Pagination bsSize="medium">{items}</Pagination>;
                callback(rows, pagination);
            }else{
                if(response.data.code === 401){
                    this.handleExpiredToken();
                }else {
                    console.error(response.data.note);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    }

    Post(callback){
        try {
            this.data.token = this.token;
            this.data.locale = this.locale;
            axios.post(global.config.BASE_URL + '/' + this.url, this.data).then(response => {
                if(response.status >= 200 && response.status < 300){
                    response.data.status = true;
                    callback(response.data);
                }else{
                    if(response.data.code === 401){
                        this.handleExpiredToken();
                    }else {
                        console.error(response.data.note);
                        callback(response.data);
                    }
                }
            });
        }catch (e) {
            console.error(e);
        }
    }

    Put(callback){
        try {
            this.data.token = this.token;
            this.data.locale = this.locale;
            axios({
                method: 'put',
                url: global.config.BASE_URL + '/' + this.url,
                data: this.data
            }).then(response => {
                if(response.status >= 200 && response.status < 300) {
                    response.data.status = true;
                    callback(response.data);
                }else{
                    if(response.data.code === 401){
                        this.handleExpiredToken();
                    }else {
                        console.error(response.data.note);
                        callback(response.data);
                    }
                }
            });
        }catch (e){
            console.error(e);
        }
    }

    Delete(callback){
        try {
            axios({
                method: 'delete',
                url: global.config.BASE_URL + '/' + this.url,
                data: {
                    token: this.token,
                    id: this.data
                }
            }).then(response => {
                if(response.status >= 200 && response.status < 300) {
                    response.data.status = true;
                    callback(response.data);
                }else{
                    if(response.data.code === 401){
                        this.handleExpiredToken();
                    }else {
                        console.error(response.data.note);
                    }
                }
            });
        }catch (e){
            console.error(e);
        }
    }

    Upload(callback){
        try {
            axios({
                config: {
                    headers: {'Content-Type': 'multipart/form-data'}
                },
                method: 'post',
                url: global.config.BASE_URL +  "/" + this.url,
                data: this.data,
            }).then(response => {
                if(response.status >= 200 && response.status < 300) {
                    callback(response.data.data.item.link);
                }else{
                    if(response.data.code === 401){
                        this.handleExpiredToken();
                    }else {
                        console.error(response.data.note);
                    }
                }
            });
        }catch (e){
            console.error(e);
        }
    }
}
