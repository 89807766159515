import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import xhr from "../components/xhr";

const entity = "طبقه";
const url = 'complex_floors';

export default class ComplexFloor extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {
                complex_id: global.config.COMPLEX_ID
            },
            message: null,
            submitLoading: false,
            complex_name: ''
        };
    }

    componentWillMount(){
        console.log("global.config.COMPLEX_ID :", global.config.COMPLEX_ID);
        if(this.id) {
            new xhr(this, url, this.id).GetOne((item) => {
                this.setState({item});
            });
        }

        new xhr(this, 'services', global.config.COMPLEX_ID).GetOne(item => {
            this.setState({complex_name: item.title})
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        if(this.id){
            let data = this.state.item;
            data.id = this.id;
            new xhr(this, url, data).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }else{
            let data = this.state.item;
            new xhr(this, url, data).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }
        setTimeout(() => {
            this.setState({
                message: null
            });
        }, 3000);
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    }

    // handleUploadImage(event){
    //     let data = new FormData();
    //     data.append('image', event.target.files[0]);
    //     data.append('type', url);
    //     new xhr(this, 'upload', data).Upload(link => {
    //         if(link){
    //             let item = this.state.item;
    //             item.image = link;
    //             this.setState(item);
    //         }
    //     });
    // }

    // handleRemoveImage() {
    //     let item = this.state.item;
    //     item.image = '';
    //     this.setState(item);
    // }

    render(){
        const {item, message, submitLoading, complex_name} = this.state;
        console.log("item :", item);
        return (
            <div>
                <h2>{this.title} {complex_name}</h2>
                <p><a href={"/complex_floors/" + global.config.COMPLEX_ID}>بازگشت به طبقات</a></p>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    <FormGroup>
                        <ControlLabel>نام</ControlLabel>
                        <FormControl
                            type="text"
                            name="name"
                            value={item.name}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>ترتیب</ControlLabel>
                        <FormControl
                            type="text"
                            name="order"
                            value={item.order}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    {/*<FormGroup>*/}
                        {/*<ControlLabel>پلن طبقه</ControlLabel>*/}
                        {/*<FormControl*/}
                            {/*name="image"*/}
                            {/*type="file"*/}
                            {/*onChange={this.handleUploadImage.bind(this)}*/}
                        {/*/>*/}
                        {/*{item.image &&*/}
                        {/*<div>*/}
                            {/*<img src={item.image} className="icon"/>*/}
                            {/*<Button bsStyle="danger" title="حذف" onClick={() => this.handleRemoveImage()}>×</Button>*/}
                        {/*</div>*/}
                        {/*}*/}
                    {/*</FormGroup>*/}
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}
                    </Button>
                </form>
            </div>
        );
    }
}