import React, { Component } from 'react';
import xhr from "../components/xhr";
import {Button, Alert} from 'react-bootstrap';

export default class AddConfirmUser extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: {}
        };
    }

    componentDidMount(){
        let id = localStorage.getItem('_add_user');
        new xhr(this, 'view_profile', id).GetOne(item => {
            this.setState({user: item});
        });
    }

    render(){
        const {user} = this.state;
        return (
            <div>
                {user.pic && <img src={user.pic} className="icon" />}
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td>نام</td>
                            <td>{user.name}</td>
                        </tr>
                        <tr>
                            <td>نام خانوادگی</td>
                            <td>{user.family}</td>
                        </tr>
                        <tr>
                            <td>درباره</td>
                            <td>{user.bio}</td>
                        </tr>
                    </tbody>
                </table>
                <Alert bsStyle="info">برای این شماره موبایل این شخص ثبت شده است، اگر صاحب کسب و کار همین فرد است مراحل را ادامه دهید و در غیر این صورت شماره موبایل دیگری را وارد کنید.</Alert>
                <Button bsStyle="warning" onClick={() => window.location = "/add/mobile"}>« تغییر شماره موبایل</Button>
                {' '}
                <Button bsStyle="primary" onClick={() => window.location = "/add/user_businesses"}>مرحله بعد »</Button>
            </div>
        );
    }
}