import React, { Component } from 'react';
import Button from "react-bootstrap/es/Button";
import xhr from "./xhr";
import Modal from "react-bootstrap/es/Modal";
import FormGroup from "react-bootstrap/es/FormGroup";
import ControlLabel from "react-bootstrap/es/ControlLabel";
import FormControl from "react-bootstrap/es/FormControl";
import {Alert} from "react-bootstrap";

export default class PickComplex extends Component {
    constructor(props){
        super(props);
        this.state = {
            business: props.business,
            show_details: false,
            item: {
                complex_id: global.config.COMPLEX_ID,
                service_id: props.child.id
            },
            complex_user_id: localStorage.getItem('user'),
            floors: [],
            message: null,
            submitLoading: false
        };
        this.handleHide = this.handleHide.bind(this);
        this.handleAddToComplex = this.handleAddToComplex.bind(this);
    }

    componentWillReceiveProps(props){
        let item = this.state.item;
        item.service_id = props.child.id;
        item.category_id = props.child.category_id;
        item.title = props.child.title;
        this.setState(item);
        new xhr(this, 'complex_details', "conditions=" + JSON.stringify({
            complex_id: global.config.COMPLEX_ID,
            service_id: props.child.id
        })).GetManyPure(response => {
            let list = response.data.list;
            if(list.length > 0){
                let item = this.state.item;
                item.title = list[0].title;
                item.floor = list[0].floor;
                item.number = list[0].number;
                this.setState(item);
            }
        });
    }

    componentDidMount(){
        new xhr(this, 'complex_floors', "conditions=" + JSON.stringify({complex_id: global.config.COMPLEX_ID}), -1).GetManyPure(response => {
            let list = response.data.list;
            let floors = [];
            for(let i = 0; i < list.length; i++){
                floors.push(<option key={list[i].id} value={list[i].name}>{list[i].name}</option>);
            }
            let item = this.state.item;
            if(list[0]) {
                item.floor = list[0].name;
            }
            this.setState({floors, item});
        });


    }

    handleChangeDetails(e){
        let target = e.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState(item);
    }

    handleHide(){
        this.setState({show_details: false});
    }

    handleAddToComplex(e){
        e.preventDefault();
        new xhr(this, 'complex_details', "conditions=" + JSON.stringify({service_id: this.state.item.service_id})).GetManyPure(response => {
            let list = response.data.list;
            if(list.length > 0){
                let data = this.state.item;
                data.id = list[0].id;
                new xhr(this, 'complex_details', data).Put(response => {
                    if(response.status){
                        let data = {
                            id: this.state.item.service_id,
                            parent_id: this.state.item.complex_id,
                            parent_user_id: this.state.complex_user_id
                        };
                        new xhr(this, 'services', data).Put(response => {
                            console.log('service :', response);
                            if(response.status){
                                this.setState({
                                    message: <Alert bsStyle="success">با موفقیت اختصاص یافت.</Alert>,
                                    submitLoading: false,
                                });
                            }else{
                                if(response.code === 544){
                                    this.setState({
                                        message: <Alert bsStyle="success">با موفقیت اختصاص یافت.</Alert>,
                                        submitLoading: false,
                                    });
                                }else{
                                    this.setState({
                                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                                        submitLoading: false,
                                    });
                                }
                            }
                        });
                    }else{
                        if(response.code === 544){
                            this.setState({
                                message: <Alert bsStyle="success">با موفقیت اختصاص یافت.</Alert>,
                                submitLoading: false,
                            });
                        }else{
                            this.setState({
                                message: <Alert bsStyle="danger">{response.note}</Alert>,
                                submitLoading: false,
                            });
                        }
                    }
                });
            }else{
                new xhr(this, 'complex_details', this.state.item).Post(response => {
                    if(response.status){
                        let data = {
                            id: this.state.item.service_id,
                            parent_id: this.state.item.complex_id,
                            parent_user_id: this.state.complex_user_id
                        };
                        new xhr(this, 'services', data).Put(response => {
                            console.log('service :', response);
                            if(response.status){
                                this.setState({
                                    message: <Alert bsStyle="success">با موفقیت اختصاص یافت.</Alert>,
                                    submitLoading: false,
                                });
                            }else{
                                this.setState({
                                    message: <Alert bsStyle="danger">{response.note}</Alert>,
                                    submitLoading: false,
                                });
                            }
                        });
                    }else{
                        this.setState({
                            message: <Alert bsStyle="danger">{response.note}</Alert>,
                            submitLoading: false,
                        });
                    }
                });
            }
        });
    }

    render(){
        const {item, message, submitLoading, floors} = this.state;

        return (
            <div>
                <Modal show={this.props.show} onHide={() => this.props.hide()} aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>جزئیات</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {message}
                        <FormGroup>
                            <ControlLabel>کسب و کار</ControlLabel>
                            <FormControl.Static>{this.props.child.title}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>عنوان</ControlLabel>
                            <FormControl
                                type="text"
                                name="title"
                                value={item.title}
                                onChange={this.handleChangeDetails.bind(this)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>طبقه</ControlLabel>
                            <FormControl
                                componentClass="select"
                                name="floor"
                                value={item.floor}
                                onChange={this.handleChangeDetails.bind(this)}
                            >
                                {floors}
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>واحد</ControlLabel>
                            <FormControl
                                type="text"
                                name="number"
                                value={item.number}
                                placeholder="مثلاً 204"
                                onChange={this.handleChangeDetails.bind(this)}
                            />
                        </FormGroup>
                        <Button
                            disabled={submitLoading}
                            bsStyle="primary"
                            onClick={(e) => this.handleAddToComplex(e)}
                        >
                            {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'اختصاص'}
                        </Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.hide()}>بستن</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}